@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
* {
  margin: 0px;
  padding: 0px;
}
html {
  font-size: 16px;
}
@import url('https://fonts.googleapis.com/css2?family=Jersey+25&family=Oswald:wght@200..700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
}

.active {
  color: rgb(255, 145, 0);
  font-weight: 500;
}
.fms value{
  color: red;
} 
/* fonts */

.jersey-25-regular {
  font-family: "Jersey 25", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.inria-serif-bold {
  font-family: "Inria Serif", serif;
  font-weight: 700;
  font-style: normal;
}
.inria-serif-light {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.navbg {
  /* background-color: #15283b; */
  /* background-color: rgb(7, 50, 70); */
  background-color: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
}

#app {
  height: 100%;
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: rgb(7, 50, 70);
  border: 3px solid white;
  width: 15px;
  padding: 7px;
  border-radius: 50%;
  height: 15px;
}

.logobg {
  background-color: rgba(255, 255, 255, 0.952);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate-square {
  animation: rotate 4s linear infinite;
}

.rotate-circle1 {
  animation: rotate 8s linear infinite;
}

.rotate-circle2 {
  animation: rotate 6s linear infinite;
}

.rotate-circle3 {
  animation: rotate 10s linear infinite;
}

@keyframes linear-move {
  0% {
    transform: translate(-10px, -20px);
  }

  25% {
    transform: translate(-100px, -100px);
  }

  50% {
    transform: translate(-100px, -50px);
  }

  75% {
    transform: translate(-100px, 100px);
  }

  100% {
    transform: translate(0, 0);
  }
}

.animate-linear-move>* {
  animation: linear-move 10s linear infinite;
}


/* clippath */

.content {
  /* transform: rotate(-45deg); */
  text-align: center;
}

/* .nav6{
  width: auto !important;
} */
/* @media screen and (max-width:1024px) {
  .nav6{
    width: 1024px !important;
  }
  
} */