.loader {
    color: blue;
    position: relative;
    font-size: 56px;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    z-index: 999;
    top: 35%;
    left:  35%;
    background-color: #fff7ed00;
  }
  .loader::after {
    content: '';  
    width: 5px;
    height: 5px;
    background: blue;
    position: absolute;
    bottom: 10px;
    right: -5px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
  }
  
  @keyframes animloader {
    0% {
      box-shadow: 10px 0 blue 20px 0 rgba(255, 255, 255, 0);
    }
    50% {
      box-shadow: 10px 0 blue, 20px 0 rgba(255, 255, 255, 0);
    }
    100% {
      box-shadow: 10px 0 blue, 20px 0 blue;
    }
  }